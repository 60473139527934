<template>
  <div>
    <BaseModalContent
      :name="name"
      :dynamic="true"
      @close="$emit('close')">
      <div>
        <h2 class="text-size-h2 text-bold mb-l">
          Необходимо обновить страницу
        </h2>
        <div class="mb-l">
          <p>
            Мы&nbsp;обновили программный интерфейс, для корректной работы
            в&nbsp;личном кабинете рекомендуем обновить страницу
          </p>
        </div>
        <BaseButton @click="handleReload">Обновить страницу</BaseButton>
      </div>
    </BaseModalContent>
  </div>
</template>

<script>
export default {
  name: "ReleaseModal",
  data() {
    return {
      name: "release-modal",
    };
  },
  methods: {
    handleReload() {
      window.location.reload();
    },
  },
};
</script>

<style></style>
