<template>
  <button
    type="button"
    class="bvi-button bvi-hide"
    :class="hover"
    title="Специальная версия сайта"
    @click.prevent="handleClick">
    <BaseIcon
      glyph="eye"
      width="24px" /><span class="bvi-text">Специальная версия сайта</span>
  </button>
</template>

<script>
export default {
  name: "BviButton",
  props: {
    theme: {
      type: String,
      default: "default",
      validator: (value) => ["default", "white", "blue"].includes(value),
    },
  },
  computed: {
    hover() {
      return this.theme === "default" ? "link link--inherit" : this.theme;
    },
  },
  methods: {
    handleClick() {
      const bvi = document.querySelector(".bvi-open");
      bvi.click();
    },
  },
};
</script>

<style scoped lang="less">
:global(.bvi-hide) {
}
.bvi-button {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-width: 32px;
  height: 32px;

  &.white {
    transition: color 0.3s;
    &:hover {
      color: #fff;
    }
  }

  &.blue {
    transition: color 0.3s;
    &:hover {
      color: @link-blue;
    }
  }
}

@media screen and (max-width: @screen-md) {
  .bvi-text {
    display: none;
  }
}
</style>
