<template>
  <div class="reg-layout">
    <header class="reg-layout__header">
      <div class="container">
        <div class="reg-layout__header-row">
          <div class="reg-layout__logo">
            <img
              src="@/assets/images/new_logo_white.svg"
              alt="Национальная технологическая олимпиада" />
          </div>
          <div class="reg-layout__box">
            <BviButton />
            <div class="reg-layout__user">
              <div
                v-if="user"
                class="u-block">
                <div class="u-block__data">
                  <div class="u-block__email">{{ user.email }}</div>
                  <a :href="`${$store.state.clientApiPrefix}/logout/`">Выйти</a>
                </div>
                <BaseAvatar
                  class="u-block__image"
                  :image="user.avatar"
                  :gender="user.sex"></BaseAvatar>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <main
      id="main-content"
      class="reg-layout__body">
      <router-view />
      <ModalsContainer />
    </main>
    <AppFooter class="reg-layout__footer">
      <template #links>
        <a
          href="https://talent.kruzhok.org/policy"
          target="_blank"
          rel="noopener noreferrer"
          >Политика обработки персональных данных</a
        >
        <a
          href="https://talent.kruzhok.org/terms_of_use"
          target="_blank"
          rel="noopener noreferrer"
          >Пользовательское соглашение</a
        >
      </template>
    </AppFooter>
  </div>
</template>

<script>
import AppFooter from "./AppFooter";
import { clientApiRequest } from "@/services/api";
import ReleaseModal from "@/modals/ReleaseModal";
import { CHECK_RELEASE_INTERVAL } from "@/constants";
import BviButton from "@/components/BviButton.vue";
export default {
  name: "RegistrationLayout",
  components: {
    BviButton,
    AppFooter,
  },
  data() {
    return {
      isModalActive: false,
      releaseInterval: null,
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/user"];
    },
    release() {
      return this.$store.state.release;
    },
  },
  created() {
    document.documentElement.classList.add("reg-layout-root");
  },
  beforeDestroy() {
    document.documentElement.classList.remove("reg-layout-root");

    clearInterval(this.releaseInterval);
  },
  mounted() {
    setTimeout(() => {
      this.checkRelease();
    }, 3000);
    this.releaseInterval = setInterval(
      this.checkRelease,
      CHECK_RELEASE_INTERVAL
    );
  },
  methods: {
    async checkRelease() {
      if (this.isModalActive) return;
      try {
        const { data } = await clientApiRequest({
          method: "GET",
          url: "/build_version",
        });
        if (data.version !== this.release) {
          this.isModalActive = true;
          this.$modal.show(
            ReleaseModal,
            {},
            {
              adaptive: true,
              height: "auto",
              scrollable: true,
              minHeight: 200,
            },
            {
              closed: () => {
                this.isModalActive = false;
              },
            }
          );
        }
      } catch (error) {
        console.log("error", error);
      }
    },
  },
};
</script>
<style lang="less">
.reg-layout-root {
  background: @bg-dark;
  color: @lp-base-color;

  body {
    background: @bg-dark;
    color: @lp-base-color;
  }
}
</style>
<style lang="less" scoped>
.reg-layout {
  display: flex;
  min-height: 100vh;
  flex-direction: column;

  &__header {
    flex-grow: 0;
    margin-bottom: 20px;
    padding: 32px 0;

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
  }

  &__box {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__logo {
    width: 210px;

    img {
      width: 100%;
    }
  }

  &__footer {
    flex-grow: 0;
  }

  &__body {
    flex-grow: 1;
  }
}

.u-block {
  display: inline-flex;
  align-items: center;

  &__data {
    flex-grow: 1;
    text-align: right;
    color: @chambray;
    .fz(14);
  }

  &__email {
    margin-bottom: 0.2em;
  }

  &__image {
    flex-grow: 0;
    flex-shrink: 0;
    width: 40px;
    overflow: hidden;
    margin-left: 20px;
    background-color: @lp-outline-color;

    & ::v-deep .base-avatar__placeholder {
      background-color: @lp-outline-color !important;
    }
  }

  a {
    color: @anakiwa;
    transition: color 0.3s;

    &:hover {
      color: lighten(@anakiwa, 10%);
      text-decoration: none;
    }
  }
  @media screen and (max-width: @screen-xs) {
    &__email {
      display: none;
    }
  }
}
</style>
